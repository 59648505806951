import { HttpClient } from '@angular/common/http';
import { Injectable, signal } from '@angular/core';
import { Globals } from '@smarttask-common/src/lib/services/globals';
import { OrganizationlistStoreService } from '../../Organization/store/organizationlistStore.service';
import { HolidayModel } from '@smarttask-common/src/lib/models/Resource/HolidayModel';
import { DateService } from '@smarttask-common/src/lib/services/General/date.service';
import { SharedService } from '../../AngularSpecific/shared.service';
import { SharedEventNameEnum } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventNameEnum';
import { SharedEventModel } from '@smarttask-common/src/lib/models/AngularSpecific/SharedEventModel';
import { HolidayStoreService } from './store/organizationHolidayStore.service';

@Injectable({
    providedIn: 'root'
})
export class HolidayService{
    page_no = signal(1);
    limit_reached = signal(false);

    constructor(
        private http: HttpClient,
        private globals: Globals,
        private dateService: DateService,
        private sharedService: SharedService,
        private organizationlistStoreService: OrganizationlistStoreService,
        private holidayStoreService: HolidayStoreService
    ){
        this.watchSelectedOrganizationId();
        this.listenToHolidayUpsert();
        this.listenToHolidayDelete();
    }

    fetchList = (query: string = undefined, page_no = 1, list_size = 50) =>{
        const organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
        let url = `${this.globals.api_base_url}${this.globals.api_version}/holiday/list/${organization_id}?page_no=${page_no}&list_size=${list_size}`;
        if(query != undefined){
            url += `&query=${encodeURIComponent(query)}`;
        }

        return this.http.get(url).toPromise().then((data: any)=>{
            let list: HolidayModel[] = data.list;
            list.forEach((holiday) => {
                holiday.date = this.dateService.serverDateTimeToLocal(holiday.date);
            })
            this.holidayStoreService.upsertMany(list);
            this.page_no.set(data.next_page_no);
            this.limit_reached.set(data.limit_reached);
            return {list, next_page_no: data.next_page_no, limit_reached: data.limit_reached};
        });
    }

    upsert = (model: HolidayModel) =>{
        //Update store
        this.holidayStoreService.upsertOne(model);

        const organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
        return this.http.post(`${this.globals.api_base_url}${this.globals.api_version}/holiday/upsert/${organization_id}`, model).toPromise();
    }

    delete = (holiday_id: string) =>{
        //Update store
        this.holidayStoreService.deleteOne(holiday_id);

        const organization_id = this.organizationlistStoreService.getSelectedOrganizationId();
        return this.http.delete(`${this.globals.api_base_url}${this.globals.api_version}/holiday/delete/${organization_id}/${holiday_id}`).toPromise();
    }

    watchSelectedOrganizationId = () =>{
        this.organizationlistStoreService.watchSelectedOrganizationId().subscribe((organization_id)=>{
            if(organization_id == undefined){
                return;
            }
            this.holidayStoreService.deleteAll();
            this.fetchList();
        })
    }

    listenToHolidayUpsert = () =>{
        this.sharedService.on(SharedEventNameEnum.organization_holiday_upsert,(event: SharedEventModel)=>{
            let model: HolidayModel = event.obj.holiday;
            this.holidayStoreService.upsertOne(model);
        })
    }

    listenToHolidayDelete = () =>{
        this.sharedService.on(SharedEventNameEnum.organization_holiday_delete,(event: SharedEventModel)=>{
            let holiday_id: string = event.obj.holiday_id;
            this.holidayStoreService.deleteOne(holiday_id);
        })
    }
}